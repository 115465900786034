// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "SolutionsMain-module--aboutMainCircleBlurBlue--c785f";
export var aboutMainCircleBlurGreen = "SolutionsMain-module--aboutMainCircleBlurGreen--475c9";
export var backgroundCircles = "SolutionsMain-module--backgroundCircles--5ec9d";
export var backgroundCirclesBlogArticles = "SolutionsMain-module--backgroundCirclesBlogArticles--56573";
export var blogsCircleBlueRight = "SolutionsMain-module--blogsCircleBlueRight--979f7";
export var casePreviewImage = "SolutionsMain-module--casePreviewImage--35e17";
export var contactOutlineCircle = "SolutionsMain-module--contactOutlineCircle--765a6";
export var fadeInBottomRight = "SolutionsMain-module--fadeInBottomRight--8f1b3";
export var reportsColumnLeft = "SolutionsMain-module--reportsColumnLeft--5087d";
export var reportsColumnRight = "SolutionsMain-module--reportsColumnRight--33efe";
export var reportsContainerWrapper = "SolutionsMain-module--reportsContainerWrapper--b00e9";
export var reportsMainArticles = "SolutionsMain-module--reportsMainArticles--77307";
export var reportsMainContainer = "SolutionsMain-module--reportsMainContainer--c6a6e";
export var reportsMainTags = "SolutionsMain-module--reportsMainTags--456f5";
export var reportsRow = "SolutionsMain-module--reportsRow--f289b";
export var reportsTag = "SolutionsMain-module--reportsTag--62803";